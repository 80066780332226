<template>
  <q-layout view="lHh Lpr lFf">
    <q-header elevated class="glossy">
      <q-toolbar>
        <q-btn
          flat
          dense
          round
          @click="leftDrawerOpen = !leftDrawerOpen"
          aria-label="Menu"
          icon="fas fa-bars"
        />

        <q-toolbar-title style="font-size:3vw;">
          Fédération Française de Hockey
        </q-toolbar-title>

        <div class="row">
          <q-btn-dropdown v-if="user" style="margin: 5px" :label='user.displayName ? user.displayName : user.email'>
            <q-list>
              <q-item clickable v-close-popup @click="onClickDeconnexion">
                <q-item-section>
                  <q-item-label>Déconnexion</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
          <q-btn v-else to="/connect" flat>Connexion</q-btn>
        </div>

      </q-toolbar>
    </q-header>

    <q-drawer
      v-model="leftDrawerOpen"
      bordered
      content-class="bg-grey-2"
    >
      <q-layout>
        <q-list>
          <q-item-label header>Navigation</q-item-label>

          <q-item v-for="route in availablesRoutes" :key="route.path"
                  :to="{name: (route.name || _.get(route, ['children', 0, 'name']))}">
            <q-item-section avatar>
              <q-icon :name="route.meta.icon"/>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ route.meta.label }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
        <q-footer>

          <q-list>
            <q-item-section>
              <q-item to="/tutoriel" exact class="text-white">
                <q-item-section avatar>
                  <q-icon name="fas fa-book"/>
                </q-item-section>
                <q-item-section>
                  <q-item-label>Tutoriel</q-item-label>
                </q-item-section>
              </q-item>
            </q-item-section>
            <q-item to="/about" exact class="text-white">
              <q-item-section avatar>
                <q-icon name="fas fa-info"/>
              </q-item-section>
              <q-item-section>
                <q-item-label>A Propos</q-item-label>
                <q-item-label caption class="text-white">{{ version }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-footer>
      </q-layout>
    </q-drawer>

    <q-page-container>
      <router-view/>
<!--      <p>-->
<!--        <ins class="adsbygoogle"-->
<!--             style="display:inline-block;height:90px"-->
<!--             data-ad-client="ca-pub-1482641358674175"-->
<!--             data-ad-slot="5753951020"></ins>-->
<!--      </p>-->
    </q-page-container>
  </q-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { USER_SIGNOUT } from '@/store/user/action-types'
import routes from '@/routes'
import { isGranted } from '@/utils/router'

const storeUser = createNamespacedHelpers('user')

export default {
  name: 'LayoutDefault',

  props: {
    version: {
      type: String,
      default: process.env.VUE_APP_VERSION
    }
  },
  data () {
    return {
      leftDrawerOpen: this.$q.platform.is.desktop
    }
  },

  mounted () {
    (window.adsbygoogle = window.adsbygoogle || []).push({})
  },

  methods: {
    ...storeUser.mapActions({
      signout: USER_SIGNOUT
    }),
    async onClickDeconnexion () {
      try {
        await this.signout()
        this.$q.notify({
          type: 'positive',
          message: 'Déconnexion réussi !',
          color: 'positive'
        })
        this.$router.push('/')
      } catch (e) {
        this.$q.notify({
          type: 'negative',
          message: 'Une erreur est arrivée : ' + e.message,
          color: 'negative'
        })
      }
    }
  },

  computed: {
    ...storeUser.mapState({
      user: 'user',
      player: 'player'
    }),
    availablesRoutes () {
      return routes.filter(e => e.meta).filter(isGranted)
    }
  }
}
</script>
