<template>
  <q-page>
    <div class="text-h6 q-pa-xs">
      <!--      <q-breadcrumbs> WTF ?-->
      <!--        <q-breadcrumbs-el icon="fa fa-home"-->
      <!--                          :to="{name: 'coach-team', params: {teamId: teamId}}"-->
      <!--                          :label="`Equipe ${team.label}`"/>-->
      <!--      </q-breadcrumbs>-->
      <router-link :to="{name: 'coach-team', params: {teamId: teamId}}" tag="span" class="cursor-pointer">
        <q-icon name="fa fa-chevron-left"/>
        Equipe {{team.label}}
      </router-link>
    </div>
    <router-view/>
  </q-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const storeTeam = createNamespacedHelpers('teams')
export default {
  name: 'Team',
  props: {
    teamId: {
      type: String
    }
  },
  computed: {
    ...storeTeam.mapGetters({
      findTeam: 'findTeam'
    }),
    team () {
      return this.findTeam(this.teamId)
    }
  }
}
</script>

<style scoped>

</style>
