<template>
  <q-page padding class="flex justify-center content-center">
    <q-card class="column wrap justify-center items-center content-center">
      <q-card-section class="q-gutter-xs">
        <p class="text-h6">Tutoriels</p>
        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vRX3wAGxnW7c_InVj2rNwCYi61h3EAgIFwwwkiTVOdY4Miw9587kp9workconGL2x6bzkCKIqm_XKFU/embed?start=false&loop=false&delayms=5000" frameborder="0" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>
export default {
  name: 'PageManuel'
}
</script>
