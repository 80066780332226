/**
 * Collection des joueurs
 * @type {string}
 */
export const DB_PLAYERS = 'players'

/**
 * Collection des équipes
 * @type {string}
 */
export const DB_TEAMS = 'teams'

/**
 * Collection des activités
 * @type {string}
 */
export const DB_DAILY_ACTIVITIES = 'daily-activities'

/**
 * Collection des weekly-forms
 * @string {string}
 */
export const DB_WEEKLY_FORMS = 'weekly-forms'

/**
 * Collection des daily-forms
 * @string {string}
 */
export const DB_DAILY_FORMS = 'daily-forms'
