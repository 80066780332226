import store from '@/store'

const securedRoles = ['admin', 'coach']
/**
 * Vérifie si un route est accessible pour l'utilisateur connecté
 * @param r
 */
export const isGranted = (r) => {
  const { meta } = r
  if (!meta) {
    return true
  }
  const { player } = store.state.user
  const requiredRoles = securedRoles.filter(role => meta[role])
  if (requiredRoles.length) {
    return player && requiredRoles.every(role => player[role])
  }
  return player || !r.meta.requireAuth
}
