<template>
  <q-page padding>
    <q-form>
      <q-card padding>
        <q-card-section class="row">
          <div class="col q-pa-md q-gutter-sm">
            <q-btn push color="teal" label="Changer image"/>
            <q-img
              v-if="user.url !== ''"
              :src="user.url"
              spinner-color="white"
              style="height: 140px; max-width: 150px">

              <template v-slot:error>
                <div class="absolute-full flex flex-center text-white">
                  Chargement impossible
                </div>
              </template>
            </q-img>
          </div>
          <div class="col">
            <q-input v-model="user.displayName" label="Nom"/>
            <q-input v-model="user.email" label="E-Mail"/>
            <q-select v-model="player.team" :options="availableTeams" label="Équipe" option-value="id"/>
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="Mise à jour" type="submit" color="primary" @click="onSubmit"/>
          <q-btn label="Annuler" type="reset" color="primary" flat class="q-ml-sm"/>
        </q-card-actions>
      </q-card>
    </q-form>
  </q-page>
</template>

<script>
import { db } from '@/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import { USER_UPDATE_PLAYER, USER_UPDATE_PROFILE } from '@/store/user/action-types'

const storeUser = createNamespacedHelpers('user')

export default {
  name: 'PagePlayer',
  data () {
    return {
      user: {},
      player: {},
      availableTeams: []
    }
  },
  firestore: {
    availableTeams: db.collection('teams')
  },
  watch: {
    _user: {
      immediate: true,
      handler: '_localBinding'
    }
  },
  computed: {
    ...storeUser.mapState({
      _user: 'user',
      _player: 'player'
    })
  },
  methods: {
    ...storeUser.mapActions({
      updateProfile: USER_UPDATE_PROFILE,
      updatePlayer: USER_UPDATE_PLAYER
    }),
    _localBinding () {
      this.user = { ...this._user }
      this.player = { ...this._player }
    },
    async onSubmit () {
      try {
        await Promise.all([
          this.updateProfile(this.user),
          this.updatePlayer({
            ...this.player,
            displayName: this.user.displayName
          })
        ])
        this._localBinding()
        this.$q.notify({
          type: 'positive',
          message: 'Le profil à été mis à jour !',
          color: 'positive'
        })
      } catch (e) {
        this.$q.notify({
          type: 'negative',
          message: 'Une erreur est survenue : ' + e.message,
          color: 'negative'
        })
      }
    }
  }
}
</script>
