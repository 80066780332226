/**
 * Action permettant de logguer l'utilisateur
 * @type {string}
 */
export const USER_LOGIN = 'login'

/**
 * Action permettant de register un nouvel utilisateur
 * @type {string}
 */
export const USER_REGISTER = 'register'

/**
 * Action permettant de déonnecter l'utilisateur
 * @type {string}
 */
export const USER_SIGNOUT = 'signout'

/**
 * Met à jour l'utilisateur courant
 * @type {string}
 */
export const USER_UPDATE_PROFILE = 'updateProfile'

/**
 * Bind le `Player` au sein du store
 * @type {string}
 */
export const USER_BIND_PLAYER = 'bindPlayer'

/**
 * Bind les 2 derniers WeeklyForms au sein du store
 * @type {string}
 */
export const USER_BIND_LATEST_WEEKLY = 'bindLatestWeekly'

/**
 * Met à jour le joueur courant
 * @type {string}
 */
export const USER_UPDATE_PLAYER = 'updatePlayer'
