import Vue from 'vue'
import _ from 'lodash'
import App from './App.vue'
import router from './router'
import store from './store'
import { firestorePlugin } from 'vuefire'
import './registerServiceWorker'
import './quasar'
import firebase from '@/utils/firebase'
import VueApexCharts from 'vue-apexcharts'

Vue.component('apexchart', VueApexCharts)

Vue.config.productionTip = false

Vue.prototype._ = _

Vue.use(firestorePlugin)

const vm = new Vue({
  router,
  store,
  render: h => h(App),
  async created () {
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  }
}).$mount('#app')

if (process.env.NODE_ENV === 'development') {
  window.vm = vm
}
