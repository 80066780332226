import store from '@/store'

import Home from './views/Home.vue'
import About from './views/About.vue'
import FormDay from './views/FormDay.vue'
import Player from './views/Player.vue'
import Connect from './views/Connect.vue'
import Rpe from './views/Rpe.vue'
import Tutoriel from './views/Tutoriel.vue'
import Admin from './views/Admin.vue'
import Coach from './views/Coach.vue'
import Error404 from '@/views/Error404'

// Coach
import Team from '@/views/Coach/Team'
import Teams from '@/views/Coach/Teams'
import TeamPlayer from '@/views/Coach/Team/TeamPlayer'
import TeamPlayers from '@/views/Coach/Team/TeamPlayers'

export default [
  {
    path: '',
    component: Home,
    name: 'home',
    meta: {
      icon: 'fas fa-home',
      label: 'Tableau de bord'
    }
  },
  {
    path: 'formday',
    component: FormDay,
    name: 'formday',
    meta: {
      requireAuth: true,
      icon: 'fas fa-clipboard-list',
      label: 'Questionnaire Journalier'
    }
  },
  {
    path: 'rpe',
    component: Rpe,
    name: 'rpe',
    meta: {
      requireAuth: true,
      icon: 'fas fa-dumbbell',
      label: 'RPE/TRIMP'
    }
  },
  {
    path: 'player',
    component: Player,
    name: 'player',
    meta: {
      requireAuth: true,
      icon: 'fas fa-user',
      label: 'Joueur'
    }
  },
  {
    path: 'connect',
    name: 'connect',
    component: Connect
  },
  {
    path: 'about',
    name: 'about',
    component: About
  },
  {
    path: 'tutoriel',
    name: 'tutoriel',
    component: Tutoriel
  },
  {
    path: 'admin',
    name: 'admin',
    component: Admin,
    meta: {
      requireAuth: true,
      admin: true,
      icon: 'fas fa-user-shield',
      label: 'Administration'
    }
  },
  {
    path: 'coach',
    component: Coach,
    meta: {
      requireAuth: true,
      coach: true,
      icon: 'fas fa-chart-area',
      label: 'Entraineur'
    },
    children: [
      {
        path: '',
        name: 'coach-teams',
        component: Teams,
        beforeEnter: (to, from, next) => {
          if (!_.get(store.state, ['user', 'player', 'admin'])) { // TODO : Cas de valentin a retirer plus tard
            return next({
              name: 'coach-team', params: { teamId: 'TxNPfSiI9N3BI3HNFhFe' } // France A
            })
          }
          next()
        }
      },
      {
        path: 'team/:teamId',
        component: Team,
        props: true,
        children: [
          {
            path: '',
            component: TeamPlayers,
            props: true,
            name: 'coach-team'
          },
          {
            path: 'player/:playerId',
            name: 'coach-team-player',
            component: TeamPlayer,
            props: true
          }
        ]
      }
    ]
  },
  {
    path: '*',
    component: Error404
  }
]
