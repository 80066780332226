<template>
  <q-page padding class="home">
    <div>
      <div class="text-h3">Tableau de bord</div>
      <p v-if="user && !user.emailVerified" class="text-center">
        Votre compte n'est pas encore activé veuillez le valider en cliquant sur le lien reçu par mail.
      </p>
      <player-dash-board :keyeded-latest-weekly="keyededLatestWeekly"/>
    </div>
  </q-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PlayerDashBoard from '@/components/PlayerDashBoard'

const storeUser = createNamespacedHelpers('user')

export default {
  name: 'PageHome',
  components: { PlayerDashBoard },
  computed: {
    ...storeUser.mapState({
      user: 'user'
    }),
    ...storeUser.mapGetters({
      keyededLatestWeekly: 'keyededLatestWeekly'
    })
  }
}
</script>
