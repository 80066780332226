import Vue from 'vue'
import Router from 'vue-router'
import DefaultLayout from './layouts/Default.vue'
import routes from '@/routes'
import { isGranted } from '@/utils/router'

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path: '/',
      component: DefaultLayout,
      children: routes
    }
  ]
})

router.beforeEach((to, from, next) => {
  return isGranted(to)
    ? next() // Return at home :-)
    : next('')
})

export default router
