import Notify from 'quasar/src/plugins/Notify.js';

/**
 * Handler générique pour le traitement des erreurs de bdd
 * @param f
 * @param optSucces
 * @param failureOpt
 */
export const errorHandler = async (f, optSucces = {}, failureOpt = {}) => {
  try {
    const r = await f()
    Notify.create({
      message: 'Les données ont été prise en compte',
      color: 'positive',
      ...optSucces
    })
    return r
  } catch (e) {
    Notify.create({
      message: 'Une erreur est survenue : ' + e.message,
      color: 'negative',
      ...failureOpt
    })
  }
}
