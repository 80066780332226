<template>
  <apexchart
    v-bind="chartRpe"
    type="area" height="250"/>
</template>

<script>
import { date } from 'quasar'
import sixJoursGlissantsMixin from '@/mixins/sixJoursGlissantsMixin'
import { MASK_EN } from '@/consts'

export default {
  name: 'ChartRpe',
  mixins: [sixJoursGlissantsMixin],
  props: {
    keyededLatestWeekly: {
      type: Object,
      default: _.stubObject
    },
    date: {
      type: String,
      default: () => date.formatDate(Date.now(), MASK_EN.date)
    }
  },
  computed: {
    chartRpe: function () {
      const { keyededLatestWeekly } = this
      const serie = { name: 'RPE/TRIMP', data: [] }
      this._mapSixDays(({ iDay, sDateFr, yearWeek }) => {
        const dailyActivities = _.get(keyededLatestWeekly, [yearWeek, 'daily-activities', iDay], [])
        let trimp = 0
        dailyActivities.forEach(e => {
          trimp = trimp + parseInt(e.trimp, 10)
        })
        if (trimp) {
          serie.data.push({
            x: sDateFr,
            y: trimp
          })
        }
      })
      return {
        options: {
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy'
            }
          }
        },
        series: [serie]
      }
    }
  }
}
</script>

<style scoped>

</style>
