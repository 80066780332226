export const CATEGORIE_FORM_DAY_PRISE_NUTRITIONNELLE = {
  key: 'priseNutritionnelle',
  label: 'Prise nutritionnelle',
  options: [
    {
      label: 'Inexistante',
      value: 0
    },
    {
      label: 'Faible qualité',
      value: 1
    },
    {
      label: 'Qualitée Moyenne',
      value: 2
    },
    {
      label: 'Qualitée élevée',
      value: 3
    }
  ],
  fields: [
    { key: 'petitDejeuner', label: 'Petit déjeuner' },
    {
      key: 'collationMatin',
      label: 'Collation matin'
    },
    {
      key: 'dejeuner',
      label: 'Déjeuner'
    },
    {
      key: 'collationApresMidi',
      label: 'Collation Après midi'
    },
    {
      key: 'diner',
      label: 'Dîner'
    },
    {
      key: 'collationSoir',
      label: 'Collation Soir'
    }
  ]
}
