import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export default {
  namespaced: true,
  state: () => ({
    user: null,
    player: null,
    latestWeekly: []
  }),
  actions,
  mutations,
  getters
}
