<template>
  <div id="app" style="background-color: LightGrey;">
    <router-view/>
    <transition leave-active-class="animated fadeOut">
      <div v-if="loading" class="fullscreen bg-grey-2">
        <ffh-crosse />
      </div>
    </transition>
  </div>
</template>

<script>
import FfhCrosse from '@/components/FfhCrosse'
export default {
  components: { FfhCrosse },
  data () {
    return {
      loading: true
    }
  },
  created () {
    setTimeout(() => {
      this.loading = false
    }, 3000)
  }
}
</script>
