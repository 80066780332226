<template>
  <q-page padding>
    <q-tabs v-model="activePanel">
      <q-tab name="connect" label="Connexion"/>
      <q-tab name="register" label="Inscription"/>
    </q-tabs>
    <q-tab-panels v-model="activePanel">
      <q-tab-panel name="connect">
        <q-form
          @submit="onSubmit"
          @reset="onReset"
          class="q-gutter-md">

          <q-input
            filled
            v-model="mail"
            label="E-Mail"
            type="mail"
            lazy-rules
            :rules="[
                  val => !!val || '* Champ requis',
                  val => validEmail(val) || 'Format mail non valide'
                ]"
          />

          <q-input
            filled
            v-model="password"
            label="Mot de passe"
            type="password"
            lazy-rules
            :rules="[
                  val => !!val || '* Champ requis',
                  val => val.length > 6 || 'Minimum 6 charactères'
                ]"
          />

          <div>
            <q-btn label="Connexion" type="submit" color="primary"/>
            <q-btn label="Annuler" type="reset" color="primary" flat class="q-ml-sm"/>
          </div>
        </q-form>
      </q-tab-panel>
      <q-tab-panel name="register">
        <q-form
          @submit="onSubmit"
          @reset="onReset"
          class="q-gutter-md">

          <q-input
            filled
            v-model="displayName"
            label="Nom"
            type="text"
            lazy-rules
            :rules="[
                  val => !!val || '* Champ requis'
                ]"
          />

          <q-input
            filled
            mask="mail"
            v-model="mail"
            label="E-Mail"
            type="mail"
            lazy-rules
            :rules="[
                  val => !!val || '* Champ requis',
                  val => validEmail(val) || 'Format mail non valide'
                ]"
          />

          <q-input
            filled
            v-model="password"
            label="Mot de passe"
            type="password"
            lazy-rules
            :rules="[
                  val => !!val || '* Champ requis',
                  val => val.length > 6 || 'Minimum 6 charactères'
                ]"
          />

          <q-input
            filled
            v-model="password2"
            label="Confirmation du mot de passe *"
            type="password"
            lazy-rules
            :rules="[
                  val => !!val || '* Champ requis',
                  val => val.length > 6 || 'Minimum 6 charactères'
                ]"
          />

          <div>
            <q-btn label="Inscription" color="primary" @click="signUp"/>
            <q-btn label="Annuler" type="reset" color="primary" flat class="q-ml-sm"/>
          </div>
        </q-form>
      </q-tab-panel>
    </q-tab-panels>
  </q-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { USER_LOGIN, USER_REGISTER } from '@/store/user/action-types'

const storeUser = createNamespacedHelpers('user')

export default {
  name: 'PageConnect',

  data () {
    return {
      activePanel: 'connect',
      mail: null,
      password: null,
      password2: null,
      displayName: null
    }
  },

  computed: {
    ...storeUser.mapState({
      user: 'user'
    })
  },

  watch: {
    user: {
      immediate: true,
      handler (v) {
        if (v) {
          this.$router.replace('/')
        }
      }
    }
  },

  methods: {
    ...storeUser.mapActions({
      login: USER_LOGIN,
      register: USER_REGISTER
    }),
    onReset () {
      this.mail = null
      this.password = null
    },

    async onSubmit () {
      const { mail, password } = this
      try {
        await this.login({ mail, password })
        this.$q.notify({
          type: 'positive',
          message: 'Connexion réussi !',
          color: 'positive'
        })
      } catch (e) {
        this.$q.notify({
          type: 'negative',
          message: 'Une erreur est arrivée : ' + e.message,
          color: 'negative'
        })
      }
    },

    async signUp () {
      const { mail, password, password2, displayName } = this
      if (password !== password2) {
        this.$q.notify({
          type: 'negative',
          message: 'Les mots de passe ne correspondent pas',
          color: 'negative'
        })
      } else {
        try {
          await this.register({ mail, password, displayName })
          this.$q.notify({
            type: 'positive',
            message: 'Votre compte a été créé',
            color: 'positive'
          })
        } catch (e) {
          this.$q.notify({
            type: 'negative',
            message: 'Une erreur est arrivée ' + e.message,
            color: 'negative'
          })
        }
      }
    },

    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>
