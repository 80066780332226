import { CATEGORIE_FORM_DAY_PRISE_NUTRITIONNELLE } from './data'

export const MASK_EN = {
  date: 'YYYY-MM-DD',
  time: 'HH:mm'
}
MASK_EN.datetime = [MASK_EN.date, MASK_EN.time].join('T')

export const MASK_FR = {
  date: 'DD/MM/YYYY',
  time: 'HH:mm',
  dayMonth: 'DD/MM'
}
MASK_FR.datetime = [MASK_FR.date, MASK_FR.time].join(' ')

export const CATEGORIES_FORM_DAY = [
  CATEGORIE_FORM_DAY_PRISE_NUTRITIONNELLE,
  {
    key: 'hydratation',
    label: 'Hydratation',
    options: [
      {
        label: 'Urine jaune foncé',
        value: 0
      },
      {
        label: 'Urine jaune',
        value: 1
      },
      {
        label: 'Urine clair',
        value: 2
      },
      {
        label: 'Urine translucide',
        value: 3
      }
    ],
    fields: [
      {
        key: 'hydratationMatin',
        label: 'Matinée'
      },
      {
        key: 'hydratationApresMidi',
        label: 'Après-midi'
      },
      {
        key: 'hydratationSoir',
        label: 'Soirée'
      }
    ]
  },
  {
    key: 'sommeil',
    label: 'Sommeil',
    fields: [
      {
        key: 'sommeilNocturne',
        label: 'Nuit',
        options: [
          {
            label: 'Nuit < 7h et réveil fatigué',
            value: 0
          },
          {
            label: 'Nuit > 7h et réveil fatigué',
            value: 1
          },
          {
            label: 'Nuit < 7h et réveil forme',
            value: 2
          },
          {
            label: 'Nuit > 7h et réveil forme',
            value: 3
          }
        ]
      },
      {
        key: 'sommeilDiurne',
        label: 'Sieste',
        options: [
          {
            label: 'Inexistante',
            value: 0
          },
          {
            label: 'Qualité médiocre et réveil fatigué',
            value: 1
          },
          {
            label: 'Endormissement mais réveil fatigué',
            value: 2
          },
          {
            label: 'Endormissement et réveil en forme',
            value: 3
          }
        ]
      }
    ]
  },
  {
    key: 'statutEmotionnel',
    label: 'Statut émotionnel',
    options: [
      {
        label: 'Elevé',
        value: 0
      },
      {
        label: 'Moyen',
        value: 1
      },
      {
        label: 'Faible',
        value: 2
      },
      {
        label: 'Inexistant',
        value: 3
      }
    ],
    fields: [
      {
        key: 'irritabilite',
        label: 'Irritabilité'
      },
      {
        key: 'stressAnxiete',
        label: 'Stress et Anxiété'
      },
      {
        key: 'fatigueNerveuse',
        label: 'Fatigue nerveuse'
      },
      {
        key: 'malheureux',
        label: 'Mal-être / Malheureux'
      }
    ]
  },
  {
    key: 'generalForm',
    label: 'Forme générale',
    options: [
      {
        label: 'Très malade',
        value: 0
      },
      {
        label: 'Fragile et peu malade',
        value: 1
      },
      {
        label: 'Santé satisfaisante',
        value: 2
      },
      {
        label: 'Grande forme, santé de fer',
        value: 3
      }
    ],
    fields: [
      {
        key: 'sante',
        label: 'Santé'
      }
    ]
  },
  {
    key: 'physicForm',
    label: 'Forme physique',
    options: [
      {
        label: 'Excellent',
        value: 10
      },
      {
        label: 'Très bien',
        value: 9
      },
      {
        label: 'Bien',
        value: 8
      },
      {
        label: 'Tendu à la palpation',
        value: 7
      },
      {
        label: 'Tendu au mouvement',
        value: 6
      },
      {
        label: 'Légère douleur à la palpation',
        value: 5
      },
      {
        label: 'Légère douleur au mouvement',
        value: 4
      },
      {
        label: 'Douleur',
        value: 3
      },
      {
        label: 'Très douloureux',
        value: 2
      },
      {
        label: 'Extrement douloureux',
        value: 1
      }
    ],
    fields: [
      {
        key: 'doms',
        label: 'DOMS'
      }
    ]
  },
  {
    key: 'hockey',
    label: 'Hockey',
    options: [
      {
        label: 'Inexistante',
        value: 0
      },
      {
        label: 'Faible',
        value: 1
      },
      {
        label: 'Moyen',
        value: 2
      },
      {
        label: 'Elevée',
        value: 3
      }
    ],
    fields: [
      {
        key: 'efficaciteDuels',
        label: 'Efficacité duels'
      },
      {
        key: 'qualiteReussiteTechnique',
        label: 'Qualité et réussite technique'
      }
    ]
  }
]
