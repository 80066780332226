import date from 'quasar/src/utils/date.js';

/**
 * Récupère l'année et le numéro de semaine pour une date donnée
 * @param {Date} d
 * @returns {[number, number]}
 */
export const getWeekNumber = (d) => {
  const _d = getPrevMonday(d)
  return [_d.getFullYear(), date.getWeekOfYear(_d).toString().padStart(2, '0')]
}

/**
 * Détermine la date du lundi de la semaine précédente
 * @param d
 * @param {number} nbWeek - Nombre de semaine de recul
 * @returns {Date}
 */
export const getPrevMonday = (d, nbWeek = 0) => {
  const day = d.getDay() || 7
  return date.addToDate(d, { days: (1 - day) - 7 * nbWeek })
}

/**
 * Retourne la date du lundi d'un numéro de semaine
 * @param year
 * @param week
 * @returns {Date}
 */
export const getMondayFromWeek = (year, week) => {
  return new Date(year, 0, (week - 1) * 7)
}
