<template>
  <q-page padding>
      <p class="text-h4">Administration</p>
  </q-page>
</template>

<script>
export default {
  name: 'PageAdmin'
}
</script>
