import firebase, { db } from '@/utils/firebase'
import * as actionTypes from './action-types'
import * as mutationTypes from './mutation-types'
import { firestoreAction } from 'vuexfire'
import { DB_PLAYERS, DB_TEAMS, DB_WEEKLY_FORMS } from '@/consts/db'
import { getWeekNumber } from '@/utils/date'

export default {
  [actionTypes.USER_LOGIN]: async ({ commit }, user) => {
    await firebase.auth().signInWithEmailAndPassword(user.mail, user.password)
  },
  [actionTypes.USER_REGISTER]: async ({ commit, dispatch }, user) => {
    const userCredential = await firebase.auth().createUserWithEmailAndPassword(user.mail, user.password)
    await userCredential.user.sendEmailVerification()
    await dispatch(actionTypes.USER_UPDATE_PROFILE, user)
    return userCredential
  },
  [actionTypes.USER_SIGNOUT]: async () => {
    await firebase.auth().signOut()
  },
  [actionTypes.USER_UPDATE_PROFILE]: async ({ commit, dispatch }, { displayName }) => {
    const _user = firebase.auth().currentUser
    await _user.updateProfile({
      displayName
    })
    commit(mutationTypes.USER_SET_USER, firebase.auth().currentUser)
  },
  // Player options
  [actionTypes.USER_BIND_PLAYER]: firestoreAction(async ({ state, dispatch, bindFirestoreRef }) => {
    const { user } = state
    const player = await bindFirestoreRef('player', db.collection(DB_PLAYERS).doc(user.uid))
    if (player === null) {
      return dispatch(actionTypes.USER_UPDATE_PLAYER, { displayName: user.displayName })
    }
    return player
  }),

  [actionTypes.USER_BIND_LATEST_WEEKLY]: firestoreAction(async ({ state, dispatch, bindFirestoreRef }) => {
    const { user } = state
    const today = new Date()
    // Semaine précédent la semaine dernière
    const prevWeek = new Date()
    prevWeek.setDate(today.getDate() - 14)
    const prevWeekInfos = getWeekNumber(prevWeek)
    // Semaine prochaine
    const nextWeek = new Date()
    nextWeek.setDate(today.getDate() + 7)
    const nextWeekInfos = getWeekNumber(nextWeek)
    return bindFirestoreRef(
      'latestWeekly',
      db.collection(`${DB_PLAYERS}/${user.uid}/${DB_WEEKLY_FORMS}`)
        .orderBy('yearWeek', 'desc')
        .where('yearWeek', '>', prevWeekInfos.join('-'))
        .where('yearWeek', '<', nextWeekInfos.join('-'))
    )
  }),

  [actionTypes.USER_UPDATE_PLAYER]: ({ state }, player) => {
    const _player = {
      ...player,
      team: player.team ? db.collection(DB_TEAMS).doc(player.team.id) : null
    }
    return db.collection(DB_PLAYERS).doc(state.user.uid).set(_player)
  }
}
