<template>
  <div>
    <div class="text-h6 q-pa-xs">{{ player.displayName || '???' }}</div>
    <player-dash-board :keyeded-latest-weekly="keyededLatestWeekly"/>
  </div>
</template>

<script>
import player from '@/store/user'
import { USER_BIND_LATEST_WEEKLY, USER_BIND_PLAYER } from '@/store/user/action-types'
import { USER_SET_USER } from '@/store/user/mutation-types'
import PlayerDashBoard from '@/components/PlayerDashBoard'

export default {
  name: 'TeamPlayer',
  components: { PlayerDashBoard },
  props: {
    playerId: {
      type: String
    }
  },
  watch: {
    playerId: {
      immediate: true,
      async handler (v) {
        if (this.playerId && !this.$store.hasModule(this.aNs)) {
          this.$store.registerModule(this.aNs, player)
          this.nsCall('commit', USER_SET_USER, { uid: this.playerId })
          await this.nsCall('dispatch', USER_BIND_PLAYER, { uid: this.playerId })
          await this.nsCall('dispatch', USER_BIND_LATEST_WEEKLY)
        }
      }
    }
  },
  computed: {
    aNs () {
      return ['teams', this.playerId]
    },
    sNs () {
      return this.aNs.join('/') + '/'
    },
    playerState () {
      return _.get(this.$store.state, this.aNs, {})
    },
    keyededLatestWeekly () {
      return this.$store.getters[this.sNs + 'keyededLatestWeekly']
    },
    player () {
      return this.playerState.player || {}
    }
  },
  methods: {
    nsCall (mth, type, payload) {
      return this.$store[mth](this.sNs + type, payload)
    }
  }
}
</script>

<style scoped>

</style>
