<template>
  <q-page class="flex content-center justify-center">
    <ffh-crosse />
    <div class="text-h1">Page introuvable :-(</div>
  </q-page>
</template>

<script>
import FfhCrosse from '@/components/FfhCrosse'
export default {
  name: 'Error404',
  components: { FfhCrosse }
}
</script>

<style scoped>

</style>
