import date from 'quasar/src/utils/date.js';
import { getWeekNumber } from '@/utils/date'
import { MASK_FR } from '@/consts'

export default {
  computed: {
    sixtDaysAgo () {
      return date.addToDate(new Date(this.date), { days: -6 })
    },
    iDay () {
      return this._getCalendarDay(new Date(this.date))
    },
    yearWeek () {
      const d = new Date(this.date)
      return getWeekNumber(d).join('-')
    },
    currentWeek () {
      return this.keyededLatestWeekly[this.yearWeek] || {}
    }
  },
  methods: {
    _getCalendarDay (oDate) {
      const d = new Date(oDate).getDay()
      // Lundi = 0, Dimanche = 6
      return d === 0 ? 6 : d - 1
    },
    _mapSixDays (f) {
      const { sixtDaysAgo } = this
      for (let i = 0; i < 7; i++) {
        const d = date.addToDate(sixtDaysAgo, { days: i })
        const iDay = this._getCalendarDay(d)
        const sDateFr = date.formatDate(d, MASK_FR.dayMonth)
        const yearWeek = getWeekNumber(d).join('-')
        f({ iDay, sDateFr, yearWeek })
      }
    }
  }
}
