<template>
  <q-page padding class="flex justify-center content-center">
    <div class="text-center">
      <p class="text-h4">A Propos</p>
      <p>Web Application de suivi des joueurs de l'équipe de France de Hockey.</p>
      <img alt="logo" src="../assets/LogoLMRSLR.png" style="width: 150px"/>
      <p>Cette application est dévelopée par Florian SALEUR et Matthieu LEMAIRE.</p>
    </div>
  </q-page>
</template>

<script>
export default {
  name: 'PageAbout'
}
</script>
