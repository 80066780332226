import Vue from 'vue'
import { vuexfireMutations } from 'vuexfire'
import Vuex from 'vuex'

import user from './user'
import teams from './teams'
import firebaseAuth from '@/store/plugins/firebaseAuth'

Vue.use(Vuex)

/**
 * Check registred module
 * @param {Array} aPath - path to module - ex: ['my', 'nested', 'module']
 * @return {Boolean}
 */
Vuex.Store.prototype.hasModule = function (aPath) {
  let m = this._modules.root
  return aPath.every((p) => {
    m = m._children[p]
    return m
  })
}

const store = new Vuex.Store({
  state: {},
  modules: {
    user,
    teams
  },
  mutations: {
    ...vuexfireMutations
  },
  plugins: [firebaseAuth],
  strict: process.env.NODE_ENV !== 'production'
})

if (module.hot) {
  // accept actions and mutations as hot modules
  module.hot.accept(['./user', './teams'], () => {
    // require the updated modules
    // have to add .default here due to babel 6 module output
    const newUser = require('./user').default
    const newTeams = require('./teams').default
    // swap in the new modules and mutations
    store.hotUpdate({
      modules: {
        user: newUser,
        teams: newTeams
      }
    })
  })
}

export default store
