import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    teams: []
  },
  actions,
  getters
}
