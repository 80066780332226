import * as mutationTypes from './mutation-types'

export default {
  [mutationTypes.USER_SET_USER]: (state, user) => {
    state.user = user
      ? {
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          uid: user.uid
        }
      : null
  }
}
