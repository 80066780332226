export default {
  props: ['value'],
  computed: {
    vModel: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
