import { firestoreAction } from 'vuexfire'
import { db } from '@/utils/firebase'
import * as actionTypes from './action-types'
import { DB_TEAMS } from '@/consts/db'

export default {
  [actionTypes.TEAMS_BIND_TEAMS]: firestoreAction(async ({ state, dispatch, bindFirestoreRef }) => {
    return bindFirestoreRef('teams', db.collection(DB_TEAMS))
  })
}
