/* eslint-disable no-console */

import { register } from 'register-service-worker'
import Notify from 'quasar/src/plugins/Notify.js';

if (process.env.NODE_ENV === 'production') {
  let updatefounded = false
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      updatefounded = true
      Notify.create({
        message: 'Une mise à jour de l\'application est en cours'
      })
      console.log('New content is downloading.')
    },
    updated (registration) {
      if (updatefounded) {
        Notify
          .create({
            message: 'La mise à jour est prête pour installation',
            actions: [{
              color: 'white',
              label: 'Rafraîchir',
              handler () {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' })
                updatefounded = false
                Notify
                  .create({
                    message: 'Installation...'
                  })
                setTimeout(() => {
                  registration.update()
                }, 3000)
              }
            }]
          })
        console.log('New content is available; please refresh.')
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
