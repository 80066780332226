<template>
  <div class="ffh-loader">
    <div class="absolute-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
        height="60mm"
        width="60mm"
        class="balle"
      >
        <g
          transform="translate(-12.989687,0.75926267)">
          <g
            transform="rotate(-30,-380.76979,-224.76948)">
            <path
              id="path5218"
              d="m -138.14167,228.9012 a 5.3540364,5.3540364 0 0 0 -2.91506,0.86868 c 1.8856,2.36712 1.92937,6.63229 0.0181,8.98757 a 5.3540364,5.3540364 0 0 0 2.89697,0.85162 5.3540364,5.3540364 0 0 0 2.90577,-0.85783 c -1.89773,-2.37947 -1.88842,-6.60896 0.005,-8.98394 a 5.3540364,5.3540364 0 0 0 -2.91042,-0.8661 z"
              style="fill:#e6e6e6;stroke-width:1.7846787"/>
            <path
              id="path5216"
              d="m -133.91247,230.97653 c -1.28317,1.69991 -1.28836,4.84778 -0.004,6.55618 a 5.3540364,5.3540364 0 0 0 1.12913,-3.27732 5.3540364,5.3540364 0 0 0 -1.125,-3.27886 z"
              style="fill:#ed2939;stroke-width:1.7846787"/>
            <path
              id="circle5178"
              d="m -142.37397,230.98053 a 5.3540364,5.3540364 0 0 0 -1.12189,3.27525 5.3540364,5.3540364 0 0 0 1.13895,3.29076 c 1.29042,-1.6996 1.25901,-4.87117 -0.0171,-6.56601 z"
              style="fill:#002395;stroke-width:1.7846787"/>
          </g>
        </g>
      </svg>
    </div>
    <div class="absolute-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 60 60"
        height="60mm"
        width="60mm"
        class="crosse"
      >
        <g
          transform="translate(-12.989687,0.75926267)">
          <g
            transform="translate(188.39846,-183.32993)">
            <path
              style="opacity:1;fill:#002395;fill-opacity:1;stroke:none;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
              d="m -135.03793,202.25295 c 0,0 -25.20121,27.54048 -27.09445,29.49987 -1.89324,1.95939 -6.3428,-0.13849 -2.3052,-4.17609 3.23976,-3.23976 -2.19076,-7.72492 -5.29193,-4.62375 -11.89196,11.89196 5.70852,22.56671 12.4414,14.17863 6.73288,-8.38808 25.45742,-32.07233 25.45742,-32.07233 z"
              id="path826"/>
            <path
              style="opacity:1;fill:#e6e6e6;fill-opacity:1;stroke:none;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
              d="m -133.93544,201.2841 11.72645,-12.86235 2.13816,1.73725 -10.89124,13.56393 z"
              id="path828"/>
            <path
              style="opacity:1;fill:#ed2939;fill-opacity:1;stroke:none;stroke-width:0.26458332px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
              d="m -122.50967,186.58427 4.04245,3.50792 1.40317,-1.70385 -4.14268,-3.37427 z"
              id="path830"/>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FfhCrosse'
}
</script>

<style scoped lang="stylus">
@keyframes crosse
  10%
    transform translate(0, -50px) rotate(10deg)
  25%
    transform translate(0, -75px) rotate(-50deg)

@keyframes balle
  0%
    transform translate(-50px, 0px) rotate(10deg)
  15%
    transform translate(-50px, 0px) rotate(10deg)
  25%
    transform translate(50px, 0px) rotate(-10deg)
  100%
    transform translate(-50px, 0px) rotate(10deg)

.ffh-loader
  .crosse
    animation crosse 3s infinite
    transform-origin top right

  .balle
    animation balle 3s infinite
</style>
