var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-page',{attrs:{"padding":""}},[_c('q-tabs',{model:{value:(_vm.activePanel),callback:function ($$v) {_vm.activePanel=$$v},expression:"activePanel"}},[_c('q-tab',{attrs:{"name":"connect","label":"Connexion"}}),_c('q-tab',{attrs:{"name":"register","label":"Inscription"}})],1),_c('q-tab-panels',{model:{value:(_vm.activePanel),callback:function ($$v) {_vm.activePanel=$$v},expression:"activePanel"}},[_c('q-tab-panel',{attrs:{"name":"connect"}},[_c('q-form',{staticClass:"q-gutter-md",on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('q-input',{attrs:{"filled":"","label":"E-Mail","type":"mail","lazy-rules":"","rules":[
                function (val) { return !!val || '* Champ requis'; },
                function (val) { return _vm.validEmail(val) || 'Format mail non valide'; }
              ]},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}}),_c('q-input',{attrs:{"filled":"","label":"Mot de passe","type":"password","lazy-rules":"","rules":[
                function (val) { return !!val || '* Champ requis'; },
                function (val) { return val.length > 6 || 'Minimum 6 charactères'; }
              ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',[_c('q-btn',{attrs:{"label":"Connexion","type":"submit","color":"primary"}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"label":"Annuler","type":"reset","color":"primary","flat":""}})],1)],1)],1),_c('q-tab-panel',{attrs:{"name":"register"}},[_c('q-form',{staticClass:"q-gutter-md",on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('q-input',{attrs:{"filled":"","label":"Nom","type":"text","lazy-rules":"","rules":[
                function (val) { return !!val || '* Champ requis'; }
              ]},model:{value:(_vm.displayName),callback:function ($$v) {_vm.displayName=$$v},expression:"displayName"}}),_c('q-input',{attrs:{"filled":"","mask":"mail","label":"E-Mail","type":"mail","lazy-rules":"","rules":[
                function (val) { return !!val || '* Champ requis'; },
                function (val) { return _vm.validEmail(val) || 'Format mail non valide'; }
              ]},model:{value:(_vm.mail),callback:function ($$v) {_vm.mail=$$v},expression:"mail"}}),_c('q-input',{attrs:{"filled":"","label":"Mot de passe","type":"password","lazy-rules":"","rules":[
                function (val) { return !!val || '* Champ requis'; },
                function (val) { return val.length > 6 || 'Minimum 6 charactères'; }
              ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('q-input',{attrs:{"filled":"","label":"Confirmation du mot de passe *","type":"password","lazy-rules":"","rules":[
                function (val) { return !!val || '* Champ requis'; },
                function (val) { return val.length > 6 || 'Minimum 6 charactères'; }
              ]},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}}),_c('div',[_c('q-btn',{attrs:{"label":"Inscription","color":"primary"},on:{"click":_vm.signUp}}),_c('q-btn',{staticClass:"q-ml-sm",attrs:{"label":"Annuler","type":"reset","color":"primary","flat":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }