<template>
  <q-page padding>
    <div class="col-sm-12 col-md-6 offset-md-3 q-pa-sm row">
      <q-btn icon="fa fa-chevron-left" round
             color="secondary"
             class="q-ma-sm"
             :disable="date === sPrevMonday"
             @click="addDay(-1)"/>
      <date-picker v-model="date"
                   label="Date" class="col"
                   :date-options="dateOptions"/>
      <q-btn icon="fa fa-chevron-right" round
             color="secondary"
             class="q-ma-sm"
             :disable="date === sToday"
             @click="addDay(1)"/>
    </div>
    <div id="chart" class="q-pa-md">
      <chart-rpe
        :date="date"
        :keyeded-latest-weekly="keyededLatestWeekly"/>
    </div>
    <div class="q-pa-md">
      <q-table
        title="RPE/TRIMP du jour"
        dense
        :data="rpe"
        :columns="columnsRpe"
      >
        <template v-slot:body="props">
          <q-tr :props="props">
            <q-td key="typeRpe" :props="props">{{ props.row.typeRpe }}</q-td>
            <q-td key="nivDiff" :props="props">{{ props.row.nivDiff }}</q-td>
            <q-td key="duree" :props="props">{{ props.row.duree }}</q-td>
            <q-td key="trimp" :props="props">{{ props.row.trimp }}</q-td>
            <q-td>
              <!--<q-icon name="fa fa-pen" @click="showModal(props.row.duree ? 'showAddRpe' : 'showAddTrimp', props.row)" style="font-size: 1rem;margin: 5px;" color="blue"/>-->
              <q-icon name="fa fa-times" @click="deleteRPE(props.row)" style="font-size: 1rem;margin: 5px;"
                      color="red"/>
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </div>
    <div class="q-pa-md q-gutter-sm">
      <q-btn label="Ajout d'un RPE" size="md" icon="fas fa-plus-circle" color="primary"
             @click="showModal('showAddRpe')"/>
      <q-btn label="Ajout d'un TRIMP" size="md" icon="fas fa-plus-circle" color="primary"
             @click="showModal('showAddTrimp')"/>
    </div>

    <q-dialog v-model="showAddRpe" persistent padding>
      <q-card>
        <q-form>
          <q-card-section>
            <div v-if="value.trimp" class="text-h6">Modification d'un RPE</div>
            <div v-else class="text-h6">Ajout d'un RPE</div>
          </q-card-section>

          <q-card-section>
            <date-picker v-model="value.date"
                         read-only
                         label="Date" class="col"
                         :date-options="dateOptions"
                         type="date"/>
            <q-badge color="secondary">
              Niveau de difficulté (Intensité 0-10)
            </q-badge>
            <q-slider v-model="value.nivDiff" :min="0" :max="10" label/>
            <q-select label="Type d'activité" :options="optionsTypeRPE" v-model="value.typeRpe"/>
            <q-input type="number" label="Durée" v-model="value.duree"/>
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn type="submit" flat label="Valider" @click="postActivity" v-close-popup/>
            <q-btn flat label="Annuler" @click="resetModel" v-close-popup/>
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showAddTrimp" persistent padding>
      <q-card>
        <q-form>
          <q-card-section>
            <div v-if="value.trimp" class="text-h6">Modification d'un Trimp</div>
            <div v-else class="text-h6">Ajour d'un Trimp</div>
          </q-card-section>
          <q-card-section>
            <date-picker v-model="value.date"
                         read-only
                         label="Date" class="col"
                         :date-options="dateOptions"
                         type="date"/>
            <q-select label="Type d'activité" :options="optionsTypeRPE" v-model="value.typeRpe"/>
            <q-input type="number" label="TRIMP" v-model="value.trimp"/>
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn type="submit" flat label="Valider" @click="postActivity" v-close-popup/>
            <q-btn flat label="Annuler" @click="resetModel" v-close-popup/>
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>

  </q-page>
</template>

<script>
import { db, Timestamp } from '@/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import { DB_PLAYERS, DB_WEEKLY_FORMS } from '@/consts/db'
import { getPrevMonday } from '@/utils/date'
import { errorHandler } from '@/utils/helpers'
import DatePicker from '@/components/fields/DatePicker'
import { MASK_EN, MASK_FR } from '@/consts'
import { date } from 'quasar'
import ChartRpe from '@/components/graphic/ChartRpe'
import sixJoursGlissantsMixin from '@/mixins/sixJoursGlissantsMixin'

const storeUser = createNamespacedHelpers('user')

const emptyModel = {
  nivDiff: 0,
  typeRpe: '',
  duree: 0,
  trimp: 0,
  date: ''
}

export default {
  name: 'PageRpeTrimp',
  mixins: [sixJoursGlissantsMixin],
  components: { ChartRpe, DatePicker },

  data () {
    return {
      columnsRpe: [
        { name: 'typeRpe', required: true, label: 'Type d\'activité', field: 'typeRpe', align: 'left', sortable: true },
        { name: 'nivDiff', align: 'center', label: 'Niveau difficultés', field: 'nivDiff', sortable: true },
        { name: 'duree', label: 'Durée (min)', field: 'duree', sortable: true },
        { name: 'trimp', label: 'TRIMP', field: 'trimp', sortable: true }
      ],
      showAddRpe: false,
      showAddTrimp: false,
      value: {
        ...emptyModel
      },
      optionsTypeRPE: ['Cadio', 'Muscu', 'Hockey'],
      loading: true,
      date: date.formatDate(Date.now(), MASK_EN.date)
    }
  },

  computed: {
    ...storeUser.mapState({
      user: 'user',
      player: 'player'
    }),
    ...storeUser.mapGetters({
      keyededLatestWeekly: 'keyededLatestWeekly'
    }),
    today () {
      return new Date()
    },
    sToday () {
      return date.formatDate(this.today, MASK_EN.date)
    },
    prevMonday () {
      const { today } = this
      return getPrevMonday(today, 1)
    },
    sPrevMonday () {
      return date.formatDate(this.prevMonday, MASK_EN.date)
    },
    dateOptions () {
      const opt = []
      const today = new Date()
      const sToday = date.formatDate(today, 'YYYY/MM/DD')
      let { prevMonday } = this
      for (let k = 0; k < 14; k++) {
        prevMonday = new Date(new Date(this.prevMonday).setDate(this.prevMonday.getDate() + k))
        const sDate = date.formatDate(prevMonday, 'YYYY/MM/DD')
        opt.push(sDate)
        if (sDate === sToday) break
      }
      return opt
    },
    rpe: function () {
      return _.cloneDeep(_.get(this.currentWeek, ['daily-activities', this.iDay], []))
    }
  },

  methods: {
    showModal (s, v) {
      if (v) {
        this.value = _.cloneDeep(v)
      } else {
        this.value.date = this.date + 'T' + date.formatDate(Date.now(), MASK_FR.time)
      }
      this[s] = true
    },
    addDay (v) {
      const d = new Date(this.date)
      d.setDate(d.getDate() + v)
      this.date = date.formatDate(d, MASK_EN.date)
    },
    resetModel () {
      this.value = {
        ...emptyModel
      }
    },
    async postActivity () {
      const { currentWeek, yearWeek, iDay, value } = this
      value.trimp = value.trimp ? parseInt(value.trimp) : value.nivDiff * value.duree
      value.date = Timestamp.fromDate(new Date())
      return errorHandler(async () => {
        const _doc = {
          ...currentWeek,
          yearWeek,
          'daily-activities': { ..._.get(currentWeek, ['daily-activities'], {}) },
          player: db.collection(DB_PLAYERS).doc(this.player.id),
          acl: {
            [this.player.id]: 'owner'
          }
        }
        var tabActivities = _.cloneDeep(_doc['daily-activities'][iDay])
        // on test si le tableau existe (présence de données)
        var tailleTab = tabActivities ? tabActivities.length + 1 : 0
        if (tabActivities) {
          tabActivities.push({ tailleTab, ...value })
        } else {
          tabActivities = [{ tailleTab, ...value }]
        }
        _doc['daily-activities'][iDay] = tabActivities
        await db
          .collection(`${DB_PLAYERS}/${this.player.id}/${DB_WEEKLY_FORMS}`)
          .doc(this.yearWeek)
          .set(_doc)
        this.resetModel()
      })
    },
    async deleteRPE (element) {
      const { currentWeek, iDay } = this
      return errorHandler(async () => {
        const _doc = _.cloneDeep(currentWeek)
        _doc['daily-activities'][iDay].splice(element.__index - 1, 1)
        await db
          .collection(`${DB_PLAYERS}/${this.player.id}/${DB_WEEKLY_FORMS}`)
          .doc(this.yearWeek)
          .set(_doc)
      })
    }
  }
}
</script>
