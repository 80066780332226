import firebase from '@/utils/firebase'
import { USER_SET_USER } from '@/store/user/mutation-types'
import { USER_BIND_LATEST_WEEKLY, USER_BIND_PLAYER } from '@/store/user/action-types'
import { TEAMS_BIND_TEAMS } from '@/store/teams/action-types'

export default (store) => {
  firebase.auth()
    .onAuthStateChanged(
      async (user) => {
        store.commit(`user/${USER_SET_USER}`, user)
        if (user) {
          const player = await store.dispatch(`user/${USER_BIND_PLAYER}`, user)
          store.dispatch(`user/${USER_BIND_LATEST_WEEKLY}`, user)
          if (['admin', 'coach'].some((k) => _.get(player, [k]))) {
            store.dispatch(`teams/${TEAMS_BIND_TEAMS}`)
          }
        }
      }
    )
}
