<template>
  <q-input :value="dateFr" filled :label="label" readonly>
    <template v-slot:prepend>
      <q-icon v-if="!readOnly" name="far fa-calendar-alt" class="cursor-pointer">
        <q-popup-proxy transition-show="scale" transition-hide="scale" ref="qDateProxy">
          <q-date v-model="vModel" :mask="maskEn" :options="dateOptions" @input="onInputDate"/>
        </q-popup-proxy>
      </q-icon>
    </template>
    <template v-slot:append>
      <q-icon v-if="hasTime && !readOnly" name="fa fa-clock" class="cursor-pointer">
        <q-popup-proxy transition-show="scale" transition-hide="scale" ref="qTimeProxy">
          <q-time v-model="vModel" :mask="maskEn" format24h @input="$refs.qTimeProxy.hide()"/>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
import vModelProxyMixin from '@/mixins/vModelProxyMixin'
import { date } from 'quasar'
import { MASK_EN, MASK_FR } from '@/consts'

export default {
  name: 'DatePicker',
  mixins: [vModelProxyMixin],
  props: {
    label: {
      type: String
    },
    type: {
      type: String,
      default: 'date'
    },
    dateOptions: {
      type: Array
    },
    readOnly: {
      type: Boolean
    }
  },
  computed: {
    maskFr () {
      return MASK_FR[this.type] || MASK_FR.date
    },
    maskEn () {
      return MASK_EN[this.type] || MASK_EN.date
    },
    dateFr () {
      return date.formatDate(new Date(this.value), this.maskFr)
    },
    hasTime () {
      return ['time', 'datetime'].indexOf(this.type) !== -1
    }
  },
  methods: {
    onInputDate () {
      this.$refs.qDateProxy.hide()
      if (this.type === 'datetime') {
        this.$refs.qTimeProxy.show()
      }
    }
  }
}
</script>

<style scoped>

</style>
