<template>
  <q-card>
    <q-card-section>
      <div class="text-h6">Formulaire journalier</div>
    </q-card-section>
    <q-card-section>
      <apexchart
        v-bind="chartJournalier"
        type="line" height="250"
      />
    </q-card-section>
    <q-card-section>
      <div class="text-h6">Nutrition du jour</div>
    </q-card-section>
    <q-card-section>
      <apexchart
        v-bind="donutNutrition"
        type="radialBar" height="350"/>
    </q-card-section>
    <q-card-section>
      <div class="text-h6">RPE / TRIMP</div>
    </q-card-section>
    <q-card-section>
      <chart-rpe :date="date" :keyeded-latest-weekly="keyededLatestWeekly"/>
    </q-card-section>
  </q-card>
</template>

<script>
import { date } from 'quasar'
import { CATEGORIES_FORM_DAY, MASK_EN } from '@/consts'
import { CATEGORIE_FORM_DAY_PRISE_NUTRITIONNELLE } from '@/consts/data'
import ChartRpe from '@/components/graphic/ChartRpe'
import sixJoursGlissantsMixin from '@/mixins/sixJoursGlissantsMixin'

export default {
  name: 'PlayerDashBoard',
  mixins: [sixJoursGlissantsMixin],
  components: { ChartRpe },
  props: {
    keyededLatestWeekly: {
      type: Object,
      default: _.stubObject
    }
  },
  data () {
    return {
      date: date.formatDate(Date.now(), MASK_EN.date)
    }
  },
  computed: {
    chartJournalier () {
      const { keyededLatestWeekly } = this
      const seriePoids = { name: 'Poids', data: [] }
      const otherSeries = CATEGORIES_FORM_DAY.map(e => ({ name: e.label, data: [] }))
      let poids = 0
      this._mapSixDays(({ iDay, sDateFr, yearWeek }) => {
        const dailyForm = _.get(keyededLatestWeekly, [yearWeek, 'daily-forms', iDay], {})
        poids = parseFloat(dailyForm.poids, 10) || poids
        seriePoids.data.push({
          x: sDateFr,
          y: poids
        })
        CATEGORIES_FORM_DAY.forEach((e, i) => {
          const sum = _.sum(Object.values(_.get(dailyForm, [e.key], {})).filter((e) => e))
          if (sum) {
            otherSeries[i].data.push({
              x: sDateFr,
              y: sum
            })
          } else {
            otherSeries[i].data.push({
              x: sDateFr,
              y: 0
            })
          }
        })
      })
      return {
        options: {
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy'
            }
          },
          yaxis: [
            {
              title: {
                text: 'Poids'
              }
            },
            ...CATEGORIES_FORM_DAY.map((e, i) => ({
              opposite: true,
              show: i === 0, // On en affiche qu'un seul
              title: {
                text: ''
              },
              max: 20
            }))
          ]
        },
        series: [seriePoids, ...otherSeries]
      }
    },
    chartRpe: function () {
      const { keyededLatestWeekly } = this
      const serie = { name: 'RPE/TRIMP', data: [] }
      this._mapSixDays(({ iDay, sDateFr, yearWeek }) => {
        const dailyActivities = _.get(keyededLatestWeekly, [yearWeek, 'daily-activities', iDay], [])
        let trimp = 0
        dailyActivities.forEach(element => {
          trimp = trimp + element.trimp
        })
        if (trimp) {
          serie.data.push({
            x: sDateFr,
            y: trimp
          })
        }
      })
      return {
        options: {
          dataLabels: {
            enabled: true
          },
          stroke: {
            curve: 'smooth'
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy'
            }
          }
        },
        series: [serie]
      }
    },
    donutNutrition: function () {
      const { currentWeek, iDay } = this
      const currentDayPriseNutritionnelle = _.get(currentWeek, ['daily-forms', iDay, 'priseNutritionnelle'], {})
      const labels = CATEGORIE_FORM_DAY_PRISE_NUTRITIONNELLE.fields.map(e => e.label)
      const series = CATEGORIE_FORM_DAY_PRISE_NUTRITIONNELLE.fields.map(e => (currentDayPriseNutritionnelle[e.key] || 0) * 100 / 3)
      return {
        options: {
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: 0,
              endAngle: 270,
              dataLabels: {
                name: {
                  show: true,
                  fontSize: '16px'
                },
                value: {
                  show: true,
                  fontSize: '22px',
                  formatter: function (value) {
                    return value * 3 / 100
                  }
                },
                total: {
                  show: false
                }
              }
            }
          },
          legend: {
            show: true,
            fontSize: '13px',
            position: 'left',
            labels: {
              useSeriesColors: true
            },
            formatter: function (seriesName, opts) {
              return seriesName + ':  ' + (opts.w.globals.series[opts.seriesIndex] * 3 / 100)
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                show: false
              }
            }
          }],
          labels
        },
        series
      }
    }
  }
}
</script>

<style scoped>

</style>
