<template>
  <q-page v-if="team">
    <q-list class="bg-white">
      <q-item-label header>
        Liste des joueurs
      </q-item-label>
      <q-item v-for="player in team.players" :key="player.id" :to="{name: 'coach-team-player', params: { playerId: player.id } }">
        <q-item-section>
          <q-item-label>{{ player.displayName }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
    <!--    <q-table title="Liste des joueurs"-->
    <!--             :data="team.players"-->
    <!--             :columns="columns"/>-->
  </q-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const storeTeam = createNamespacedHelpers('teams')
export default {
  name: 'TeamPlayers',
  props: {
    teamId: {
      type: String
    }
  },
  data () {
    return {
      columns: [
        {
          name: 'displayName',
          required: true,
          label: 'Nom',
          align: 'left',
          field: row => row.displayName || row.email,
          sortable: true
        }
      ]
    }
  },
  computed: {
    ...storeTeam.mapGetters({
      findTeam: 'findTeam'
    }),
    team () {
      return this.findTeam(this.teamId)
    }
  }
}
</script>

<style scoped>

</style>
