<template>
  <q-page padding class="row">
    <div class="col-sm-12 col-md-6 offset-md-3 q-pa-sm row">
      <q-btn icon="fa fa-chevron-left" round
             color="secondary"
             class="q-ma-sm"
             :disable="date === sPrevMonday"
             @click="addDay(-1)"/>
      <date-picker v-model="date"
                   label="Date" class="col"
                   :date-options="dateOptions"/>
      <q-btn icon="fa fa-chevron-right" round
             color="secondary"
             class="q-ma-sm"
             :disable="date === sToday"
             @click="addDay(1)"/>
    </div>
    <q-card padding class="col-12">
      <q-form
          @submit="postFormulaire"
          @reset="resetModel">
        <q-card-section>
          <div class="text-h6">Poids</div>
          <q-input v-model.number="value.poids" label="Poids"/>
        </q-card-section>
        <q-card-section v-for="cat in $options.CATEGORIES_FORM_DAY" :key="cat.key">
          <div class="text-h6">{{ cat.label }}</div>
          <q-select v-for="field in cat.fields" :key="field.key"
                    v-model="value[cat.key][field.key]" :options="field.options || cat.options"
                    emit-value
                    map-options
                    lazy-rules
                    :rules="[
                      val => val !== null && val !== '' || '* Champ requis'
                    ]"
                    :label="field.label"/>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn label="Enregistrer" type="submit" color="primary"/>
         <q-btn label="Annuler" type="reset" color="primary" flat class="q-ml-sm"/>
        </q-card-actions>
      </q-form>
    </q-card>
  </q-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { db } from '@/utils/firebase'
import { DB_PLAYERS, DB_WEEKLY_FORMS } from '@/consts/db'
import { errorHandler } from '@/utils/helpers'
import DatePicker from '@/components/fields/DatePicker'
import { date } from 'quasar'
import { MASK_EN, CATEGORIES_FORM_DAY } from '@/consts'
import { getPrevMonday, getWeekNumber } from '@/utils/date'

const storeUser = createNamespacedHelpers('user')

const emptyModel = {
  poids: 0
}
CATEGORIES_FORM_DAY.forEach((cat) => {
  const o = {}
  cat.fields.forEach((field) => {
    o[field.key] = null
  })
  emptyModel[cat.key] = o
})

export default {
  name: 'PageFormDay',
  components: { DatePicker },
  CATEGORIES_FORM_DAY,
  data () {
    return {
      date: date.formatDate(Date.now(), MASK_EN.date),
      value: {
        ..._.cloneDeep(emptyModel)
      }
    }
  },

  computed: {
    ...storeUser.mapState({
      user: 'user',
      player: 'player',
      latestWeekly: 'latestWeekly'
    }),
    ...storeUser.mapGetters({
      keyededLatestWeekly: 'keyededLatestWeekly'
    }),
    yearWeek () {
      const d = new Date(this.date)
      return getWeekNumber(d).join('-')
    },
    currentWeek () {
      return this.keyededLatestWeekly[this.yearWeek] || {}
    },
    iDay () {
      const d = new Date(this.date).getDay()
      // Lundi = 0, Dimanche = 6
      return d === 0 ? 6 : d - 1
    },
    today () {
      return new Date()
    },
    sToday () {
      return date.formatDate(this.today, MASK_EN.date)
    },
    prevMonday () {
      const { today } = this
      return getPrevMonday(today, 1)
    },
    sPrevMonday () {
      return date.formatDate(this.prevMonday, MASK_EN.date)
    },
    dateOptions () {
      const opt = []
      const today = new Date()
      const sToday = date.formatDate(today, 'YYYY/MM/DD')
      let { prevMonday } = this
      for (let k = 0; k < 14; k++) {
        prevMonday = new Date(new Date(this.prevMonday).setDate(this.prevMonday.getDate() + k))
        const sDate = date.formatDate(prevMonday, 'YYYY/MM/DD')
        opt.push(sDate)
        if (sDate === sToday) break
      }
      return opt
    }
  },

  watch: {
    date: {
      immediate: true,
      handler () {
        this.value = {
          ..._.cloneDeep(emptyModel),
          ..._.cloneDeep(_.get(this.currentWeek, ['daily-forms', this.iDay], {}))
        }
      }
    }
  },

  methods: {
    addDay (v) {
      const d = new Date(this.date)
      d.setDate(d.getDate() + v)
      this.date = date.formatDate(d, MASK_EN.date)
    },
    resetModel () {
      this.value = {
        ..._.cloneDeep(emptyModel)
      }
    },
    async postFormulaire () {
      const { currentWeek, yearWeek, iDay, value } = this
      return errorHandler(async () => {
        const _doc = {
          ...currentWeek,
          yearWeek,
          'daily-forms': { ..._.get(currentWeek, ['daily-forms'], {}) },
          player: db.collection(DB_PLAYERS).doc(this.player.id),
          acl: {
            [this.player.id]: 'owner'
          }
        }
        _doc['daily-forms'][iDay] = { ...value }
        await db
          .collection(`${DB_PLAYERS}/${this.player.id}/${DB_WEEKLY_FORMS}`)
          .doc(this.yearWeek)
          .set(_doc)
        this.$router.push('/')
      })
    }
  }
}
</script>
