import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyC0RDp4XxGS1NFTjqwxsBTz78o6VGZIzJs',
  authDomain: 'ffhockey-28a4f.firebaseapp.com',
  databaseURL: 'https://ffhockey-28a4f.firebaseio.com',
  projectId: 'ffhockey-28a4f',
  storageBucket: 'ffhockey-28a4f.appspot.com',
  messagingSenderId: '611788574976',
  appId: '1:611788574976:web:6b0e802dcb2d7069e063e5',
  measurementId: 'G-LHG67H9RSV'
}

export const db = firebase
  .initializeApp(firebaseConfig)
  .firestore()

if (process.env.NODE_ENV === 'development') {
  window.db = db
}

const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

export default firebase
