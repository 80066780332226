<template>
  <q-list class="bg-white">
    <q-item-label header>
      Liste des équipes
    </q-item-label>
    <q-item v-for="team in teams" :key="team.id" :to="{name: 'coach-team', params: { teamId: team.id } }">
      <q-item-section>
        <q-item-label>{{ team.label }}</q-item-label>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const storeTeams = createNamespacedHelpers('teams')

export default {
  name: 'Teams',
  computed: {
    ...storeTeams.mapState({
      teams: 'teams'
    })
  }
}
</script>

<style scoped>

</style>
